'use client'
import { useRef } from "react";
import Image from "next/image";

import { useIsVisible } from "@hooks/useIsVisible";
import useScrollPosition from "@hooks/useScrollPosition";

import { Footer, Header } from "./_views";

export default function HomeLayout({ children }) {
  const footerRef = useRef();
  const isVisible = useIsVisible(footerRef);
  const { yScrollPosition } = useScrollPosition();

  const isScrolled = yScrollPosition > 20;
  return (
    <section className="cont_layout_home">
      <header className={isScrolled ? 'scrolled' : ''}>
        <Header isScrolled={isScrolled} />
      </header>
      <main>
        {children}
        <section className={`cont_social_media_left ${isVisible ? 'opacity_0' : ''}`}>
          {/* <a href="https://twitter.com/mercadoazulmx" className="icon twitter" target="_blank">
            <Image src={TwitterLogo} alt="Twitter" />
          </a> */}
          <a href="https://www.facebook.com/mercadoazulmexico/" className="icon facebook" target="_blank" rel="noopener noreferrer">
            <Image src='/assets/image/social-media/facebook.svg' alt="Facebook" height={32} width={32} />
          </a>
          <a href="https://www.instagram.com/mercadoazulmexico/" className="icon instagram" target="_blank" rel="noopener noreferrer">
            <Image src='/assets/image/social-media/instagram.svg' alt="Instagram" height={32} width={32} />
          </a>
          <a href="https://www.youtube.com/@mercadoazulmexico" className="icon youtube" target="_blank" rel="noopener noreferrer">
            <Image src='/assets/image/social-media/youtube.svg' alt="Youtube" height={32} width={32} />
          </a>
          <a href="mailto:contacto@mercadoazul.com.mx?Subject" className="icon facebook" target="_blank" rel="noopener noreferrer">
            <Image src='/assets/image/social-media/email.svg' alt="Facebook" height={32} width={32} />
          </a>
        </section>
      </main>
      <footer ref={footerRef}>
        <Footer />
      </footer>
    </section>
  );
}