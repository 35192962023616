'use client'
import { useContext, useState } from "react"
import Link from "next/link"
import Image from "next/image"

import { SellerContext } from "@context/sellerContext"
import { SIZES } from "@constants/variables/sizes"
import useWindowSize from "@hooks/useWindowSize"
import { Button } from "@components"


export default function HeaderHome({ isScrolled }) {
  const { loggedIn: { isLoggedIn } } = useContext(SellerContext)
  const [openedMenu, setOpenedMenu] = useState(false)
  const windowSize = useWindowSize()

  const changeStateMenu = () => {
    setOpenedMenu(!openedMenu)
  }

  return (
    <nav className={`cont_comp_header_header_home ${isScrolled ? 'scrolled' : ''}`}>
      {windowSize.width <= SIZES.MAX_SIZE_TABLET ?
        <>
          <Button className="button_menu" textButton="" type="text" icon="MenuOutlined" onClick={changeStateMenu} />
          <div className={`menu_mobile ${!openedMenu && 'hide'}`}>
            <ul>
              <li>
                <Link onClick={changeStateMenu} href="/#our-history">Nosotros</Link>
              </li>
              <li>
                <Link onClick={changeStateMenu} href="/#contact-us">Contáctanos</Link>
              </li>
              <li>
                <Link onClick={changeStateMenu} href="/facturar">Facturar mi compra</Link>
              </li>
              <li>
                <Link onClick={changeStateMenu} href="/prices">Precios</Link>
              </li>
              <li>
                {isLoggedIn ?
                  <Link onClick={changeStateMenu} href="/dashboard/invoices/general/summary"><button className="btn_login">Dashboard</button></Link>
                  :
                  <Link onClick={changeStateMenu} href="/auth/login"><button className="btn_login">Iniciar sesión</button></Link>
                }
              </li>
            </ul>
          </div>
        </>
        :
        <>
          <div className="left">
            <Image src='/assets/image/logo/header-home.png' alt='logo' className='logo' width={500} height={500} />
            <ul>
              <li>
                <Link href="/#our-history">Nosotros</Link>
              </li>
              <li>
                <Link href="/#contact-us">Contáctanos</Link>
              </li>
              <li>
                <Link href="/prices">Precios</Link>
              </li>
            </ul>
          </div>
          <div className="right">
            {isLoggedIn ?
              <Link href="/dashboard/invoices/general/summary"><button className="btn_login">Dashboard</button></Link>
              :
              <Link href="/auth/login"><button className="btn_login">Iniciar sesión</button></Link>
            }
          </div>
        </>
      }
    </nav>
  )
}